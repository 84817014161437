.rootTouchBuffer {
  /* Position */
  position: absolute;
  top: unset;
  margin-top: -22px;
  margin-left: -19px;
  /* Layout */
  width: 44px;
  height: 44px;
  padding: 0;
  border: 0;
}

.rootTouchBufferNoTracks {
  /* Position */
  position: absolute;
  top: unset;
  margin-top: -13px !important;
  margin-left: -19px;
  /* Layout */
  width: 44px;
  height: 44px;
  padding: 0;
  border: 0;
}

.visibleHandle {
  /* Position */
  position: absolute;
  top: 12px;
  margin-left: 12px;
  /* Layout */
  width: 24px;
  height: 24px;
  border: 1px solid var(--matterColorLight);
  border-radius: 50%;
  background: #474747;
  cursor: pointer;
  background-image: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L5 5L1 9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 61%;
  background-position-y: 6px;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  }
}

.visibleHandleTowerData {
  /* Position */
  position: absolute;
  top: 9px;
  margin-left: 11px;
  /* Layout */
  width: 24px;
  height: 24px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background: #474747;
  cursor: pointer;
  background-image: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L5 5L1 9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 61%;
  background-position-y: 6px;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  }
}

.dragged {
  transform: scale(1.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}
