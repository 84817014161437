@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportSmall) {
    column-count: 2;
  }
  @media (--viewportLargeWithPaddings) {
    column-count: 3;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 6px 0;
  opacity: 0.4;
  text-decoration: line-through;
  color: var(--colorBlack);
  @media (--viewportSmall) {
    padding: 16px 0 3px 0;
  }
  &.selectedItem {
    opacity: 1;
    text-decoration: none;
    & .labelWrapper {
      & > img {
        position: relative;
        bottom: 2px;
        margin-right: 6px;
        width: 24px;
        height: 24px;
      }
      & > svg {
        opacity: 1;
      }
    }
  }
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: inline-block;
  & > svg {
    margin-right: 10px;
  }
}

.selectedLabel,
.notSelectedLabel {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  color: var(--colorBlack);
  position: relative;
  text-decoration: line-through;
}
