.verifiedEmailContainer {
  text-align: center;
  & .emailLogos {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 16px;
  }
}

.helperLink {
  cursor: pointer;
  border: 1px solid #dededede;
  border-radius: 4px;
  padding: 7px 10px !important;
  &svg {
    stroke: var(--marketplaceColor);
  }
}
