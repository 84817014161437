@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
}

.icon {
  display: flex;
  width: 18px;
  align-self: stretch;
  background-color: var(--colorWhite);
  @media (--viewportMedium) {
    width: 24px;
  }
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.input {
  flex-grow: 1;
  height: 50px;
  padding-left: 8px;
  margin: 0;
  line-height: unset;
  @media (--viewportMedium) {
    padding-left: 10px;
  }
  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

/*
Predictions container can be overriden with new container styles for
size and position, etc.
*/
.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 100%;
  margin-top: 10px;
  border-radius: 4px;
  left: 0;
  background-color: var(--colorWhite);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  & ul {
    & li {
      text-align: left;
    }
  }
}

.predictionsRootGoogle {
  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

.predictionsRootMapbox {
  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/*
The Google Maps API TOS requires us to show a Powered by Google logo
next to the autocomplete service predictions. It is rendered to the
bottom of the container.
*/
.poweredByGoogle {
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 18px;
  background-image: url(./images/powered_by_google_on_non_white_hdpi.png);
  background-size: auto 18px;
  background-position: center;

  @media (--viewportMedium) {
    background-position: center left 36px;
  }
}

/* List of predictions, with a responsive padding size */
.predictions {
  composes: marketplaceBodyFontStyles from global;

  margin: 0;
  padding: 14px 0;

  & li {
    color: var(--colorGrey500);
    transition: var(--transitionStyleButton);
    margin: 0 0 10px 0; /* Remove the double white space */
    line-height: 24px;
    border-left: 6px solid var(--colorWhite);
    font-size: 14px;
    line-height: 120%;
    /* Assign enough vertical padding to make the element at least 44px high */
    padding: 4px 24px;

    @media (--viewportMedium) {
      padding: 4px 24px;
    }

    &:hover,
    &.highlighted {
      cursor: pointer;
      border-left: 6px solid var(--marketplaceColor);
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      color: var(--marketplaceColor);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.currentLocation {
  composes: p from global;
  margin: 0;
}

.currentLocationIcon {
  margin-right: 10px;
  margin-bottom: 1px;
}
.landingLocation {
  background-color: var(--colorWhite);
  padding: 0px 10px;
  border-radius: 7px;
  @media (--viewportMedium) {
    padding: 0px 14px;
  }
  & .icon {
    border: none;
    background-color: transparent;
  }
  & .input {
    background-color: var(--colorWhite);
    border: none;
    box-shadow: none;
    outline: none;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #2e3a4f;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 20px;
    }
    &::placeholder {
      opacity: 1;
      color: #2e3a4f;
    }
  }
}
