.root {
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.prev,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Reset <a> tag sizing */
  line-height: 0;
  font-size: 0;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: var(--colorGrey50);
  & > svg {
    width: 20px;
    height: 12px;
  }
  &.disabled {
    &:hover {
      color: var(--colorGrey300);
      background-color: var(--colorGrey50);
      & > svg {
        &.disabled {
          fill: var(--colorGrey300);
          stroke: var(--colorGrey300);
        }
      }
    }
  }
  &:hover {
    color: var(--colorWhite);
    background-color: var(--marketplaceColor);
    & > svg {
      fill: var(--colorWhite);
      stroke: var(--colorWhite);
    }
  }
}

.prev {
  /* Minimum touch size 44px (svg width + padding) */
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.disabled,
.disabled:hover {
  fill: var(--colorGrey300);
  stroke: var(--colorGrey300);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
  & > * {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
}

.toPageLink {
  /* Dimensions */
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: var(--colorGrey50);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* Color for svg icons */
  color: var(--colorGrey700);
  transition: var(--transition);

  &:hover {
    transition: var(--transition);
    text-decoration: none;
    color: var(--colorBlack);
    background-color: var(--colorGrey100);
  }
}

.currentPage {
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
}

.paginationGap {
  /* Dimensions */

  /* Color for svg icons */
  color: var(--colorGrey700);

  /* Ellipsis is raised from baseline */
}
