@import '../../styles/customMediaQueries.css';
.heroContainer {
  background-image: url('../../assets/register-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  background-size: cover;
  padding: 100px 24px 50px;
  min-height: 100dvh;
  align-items: center;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  & .contentWidth {
    position: relative;
    z-index: 1;
    max-width: 1280px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    @media (--viewportMedium) {
      flex-direction: row;
    }
    & .heroContent {
      justify-content: center;
      flex-direction: column;
      width: 100%;
      display: none;
      @media (--viewportMedium) {
        width: 50%;
        display: flex;
      }
      & > h1 {
        color: var(--colorWhite);
        margin: 0 0 4px 0;
      }
      & > p {
        color: var(--colorWhite);
        margin: 0;
      }
      & .heroSearchSec {
        width: 100%;
        & .landingSearchForm {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
          max-width: 100%;
          margin: 0 auto;

          @media (--viewportSmall) {
            max-width: 90%;
          }
          @media (--viewportMedium) {
            max-width: 60%;
          }
          @media (--viewportLarge) {
            flex-wrap: nowrap;
            max-width: 100%;
          }
          & .locationSearch {
          }
        }
      }
    }
    & .heroForm {
      width: 100%;
      @media (--viewportMedium) {
        width: 50%;
      }
    }
  }
}
.title {
  composes: h1 from global;
}
.sectionSubHeading {
  composes: sectionSubHeading from global;
}
