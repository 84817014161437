@import '../../styles/customMediaQueries.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  @media (--viewportLarge) {
    padding-top: 80px;
  }
}

.content {
  max-width: 587px;
  width: 80%;
}

.number {
  composes: marketplaceHeroTitleFontStyles from global;
  text-align: center;
  color: var(--marketplaceColor);
  margin: 1px 0 0 0;

  @media (--viewportMedium) {
    margin: 0;
  }
  & > img {
    max-width: 100%;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  text-align: center;
  margin-top: 16px;
  color: var(--colorBlack);

  @media (--viewportMedium) {
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  margin-bottom: 0;
  color: var(--colorGrey600);

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.searchForm {
  max-width: 500px;
  width: 100%;
  margin: 48px auto;
  border: solid 1px var(--colorGrey100);
  border-radius: 10px;
}
