@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex !important;
  flex-direction: column;
  background-color: var(--colorGrey50);
  @media (--viewportLarge) {
    padding-bottom: 260px;
  }
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0px auto;
  padding: 20px 24px 70px;
  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    padding: 20px 24px 80px;
    max-width: 80%;
    justify-content: center;
  }
  @media (--viewportMLarge) {
    max-width: 60%;
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--colorWhite);
  border: solid 1px var(--colorGrey100);
}

.sideNav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 40px;
  padding-bottom: 0px;
  & h1 {
    align-self: center;
    font-size: 30px;
    color: var(--colorBlack);
    /* Layout */
    margin-top: 0;
    margin-bottom: 24px;
    width: 100%;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  display: flex;
  height: 100%;
  &:not(:last-child) {
    margin-right: 24px;
  }
}

.bottomNavbar {
  display: none;
  justify-content: space-around;
  position: fixed;
  border-radius: 6px;
  margin: 0 auto;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9;
  border-top: solid 1px #ddd;
  /* box-shadow: 0px 0px 40px #c9c9c9; */
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;

  @media (max-width: 767px) {
    display: flex;
    padding: 0px 2px;
  }

  & > a {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #000;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10px 10px 10px;
    /* opacity: 0.6; */

    @media (max-width: 480px) {
      padding: 8px 8px 0px;
      font-size: 13px;
    }

    @media (max-width: 375px) {
      font-size: 11px;
    }

    &.active {
      opacity: 1;
    }

    & > svg,
    & > img {
      width: 40px;
      margin-bottom: 1px;
      margin-top: 1px;

      @media (max-width: 480px) {
        width: 30px;
      }
    }
  }
}

.navLink {
  cursor: pointer;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  & .inbox {
    color: #333;
    text-decoration: none;

    &:hover {
      color: var(--marketplaceColor);
    }
  }

  & .badge {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    font-size: 10px;
    line-height: 16px;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    right: 3px;
  }

  &:first-child {
    margin-left: 0;
  }

  & > svg {
    color: var(--matterColorDark);

    &:hover {
      color: var(--marketplaceColor);
    }

    &.cartIcon {
      width: 25px;
    }

    & > path {
    }
  }
}

.homeIcon {
  & > svg {
    & > path {
      fill: none;
      stroke: #000000;
      stroke-width: 1px;
    }
  }
}
