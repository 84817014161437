@import '../../../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--colorWhite);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    flex-grow: 1;

    position: relative;

    display: flex;
    flex-direction: column;
    border-radius: var(--borderRadius);
    border-bottom: none;

    min-height: 100vh;
    height: 72%;
    @media screen and (min-width: 1024px) and (max-width: 1440px) {
      height: 87% !important;
    }
    @media screen and (min-width: 1440px) and (max-width: 2560px) {
      height: 88% !important;
    }

    width: 96%;

    @media (--viewportMedium) {
      max-width: 1280px;
      flex-grow: 0;
      min-height: auto;
      height: auto;
      margin-top: 8vh;
      margin-bottom: 5vh;
    }
  }
}
.loginModalContainer {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--colorWhite);
  @media (--viewportMedium) {
    flex-direction: row;
  }
  & .loginModalForm {
    width: 100%;
    padding: 50px 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (--viewportMedium) {
      width: 50%;
    }
    @media (--viewportLargeWithPaddings) {
      padding: 60px 38px;
      width: 45%;
    }
    & .content {
      width: 100%;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  & .loginModalImg {
    width: 50%;
    display: none;
    @media (--viewportMedium) {
      display: block;
    }
    @media (--viewportLargeWithPaddings) {
      width: 55%;
    }
    & > img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPortal {
  composes: isOpen;
  display: block;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  composes: marketplaceModalCloseStyles from global;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.closeLight {
  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorWhite);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
