@import '../../../../styles/customMediaQueries.css';
.landingDateRange {
  & :global(.DateRangePicker_picker) {
    width: 100%;
    @media (--viewportLarge) {
      width: auto;
    }
  }
  @media (max-width: 1023px) {
    & :global(.DayPickerNavigation_button__verticalDefault) {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
    & :global(.DayPicker_weekHeader) {
      background-color: var(--colorWhite);
    }
  }
  & :global(.DateRangePickerInput_arrow) {
    display: none;
  }
  & :global(.DateInput_input) {
    border: none;
    background-color: var(--colorWhite);
    border-radius: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2e3a4f;
    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 20px;
    }
    &::placeholder {
      opacity: 1;
      color: #2e3a4f;
    }
  }
  & :global(.DateInput) {
    width: calc(100% / 2 - 6px);
    border-radius: 7px;
    margin-right: 12px;
    position: relative;
    @media (--viewportLarge) {
      width: calc(100% / 2 - 12px);
    }
    &:last-of-type {
      margin-right: 0;
      @media (--viewportLarge) {
        margin-right: 12px;
      }
    }
    &:first-of-type {
      &::before {
        background-image: url('../../../../assets/calendar.svg');
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      background-image: url('../../../../assets/calendar-remove.svg');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      width: 18px;
      height: 18px;
      z-index: 1;
      transform: translateY(-50%);
      @media (--viewportMedium) {
        width: 22px;
        height: 22px;
      }
    }
  }
  & :global(.DateRangePickerInput_clearDates) {
    right: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 5px;
    @media (--viewportMedium) {
      right: 4px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
    @media (--viewportLarge) {
      right: 16px;
    }
  }
  & :global(.DateInput > input) {
    height: 50px;
    border-radius: 7px;
    border: none;
    padding-left: 30px;
    @media (--viewportMedium) {
      padding-left: 40px;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  & :global(.DateRangePicker),
  & :global(.DateRangePickerInput) {
    width: 100%;
    position: relative;
  }
  & :global(.DateRangePickerInput_clearDates__hide) {
    position: absolute;
    right: 0;
    top: 10px;
  }
  & :global(.DateRangePicker_picker) {
    top: 100% !important;
    margin-top: 9px;
  }
}
