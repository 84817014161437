.root {
  position: relative;
  margin: 0 10px;
  width: calc(100% - 22px);
  height: 24px;
}

.activeHandle {
  z-index: 1;
}

.activeHandleNoTrack {
  z-index: 1;
  margin-top: -13px !important;
}
