@import '../../../styles/customMediaQueries.css';

.GlobalCountryList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  margin-top: 24px;
  @media (--viewportSmall) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
  }
  & .GlobalCountryList__listItem {
    & .GlobalCountryList__link {
      font-size: 16px;
      color: var(--colorGrey600);
      & > img {
        margin-right: 10px;
      }
      &:hover {
        text-decoration: none;
        color: var(--marketplaceColor);
      }
    }
  }
}
.Flag {
  width: 21px;
  height: 15px;
  -o-object-fit: none;
  object-fit: none;
}

.FlagCountryAD {
  -o-object-position: -10px -10px;
  object-position: -10px -10px;
}

.FlagCountryAE {
  -o-object-position: -41px -10px;
  object-position: -41px -10px;
}

.FlagCountryAG {
  -o-object-position: -72px -10px;
  object-position: -72px -10px;
}

.FlagCountryAL {
  -o-object-position: -103px -10px;
  object-position: -103px -10px;
}

.FlagCountryAM {
  -o-object-position: -134px -10px;
  object-position: -134px -10px;
}

.FlagCountryAO {
  -o-object-position: -165px -10px;
  object-position: -165px -10px;
}

.FlagCountryAR {
  -o-object-position: -196px -10px;
  object-position: -196px -10px;
}

.FlagCountryAT {
  -o-object-position: -227px -10px;
  object-position: -227px -10px;
}

.FlagCountryAU {
  -o-object-position: -258px -10px;
  object-position: -258px -10px;
}

.FlagCountryAZ {
  -o-object-position: -289px -10px;
  object-position: -289px -10px;
}

.FlagCountryBA {
  -o-object-position: -320px -10px;
  object-position: -320px -10px;
}

.FlagCountryBD {
  -o-object-position: -351px -10px;
  object-position: -351px -10px;
}

.FlagCountryBE {
  -o-object-position: -382px -10px;
  object-position: -382px -10px;
}

.FlagCountryBF {
  -o-object-position: -413px -10px;
  object-position: -413px -10px;
}

.FlagCountryBG {
  -o-object-position: -444px -10px;
  object-position: -444px -10px;
}

.FlagCountryBH {
  -o-object-position: -475px -10px;
  object-position: -475px -10px;
}

.FlagCountryBJ {
  -o-object-position: -506px -10px;
  object-position: -506px -10px;
}

.FlagCountryBN {
  -o-object-position: -10px -35px;
  object-position: -10px -35px;
}

.FlagCountryBO {
  -o-object-position: -41px -35px;
  object-position: -41px -35px;
}

.FlagCountryBR {
  -o-object-position: -72px -35px;
  object-position: -72px -35px;
}

.FlagCountryBS {
  -o-object-position: -103px -35px;
  object-position: -103px -35px;
}

.FlagCountryBT {
  -o-object-position: -134px -35px;
  object-position: -134px -35px;
}

.FlagCountryBW {
  -o-object-position: -165px -35px;
  object-position: -165px -35px;
}

.FlagCountryCA {
  -o-object-position: -196px -35px;
  object-position: -196px -35px;
}

.FlagCountryCD {
  -o-object-position: -227px -35px;
  object-position: -227px -35px;
}

.FlagCountryCG {
  -o-object-position: -258px -35px;
  object-position: -258px -35px;
}

.FlagCountryCH {
  -o-object-position: -289px -35px;
  object-position: -289px -35px;
}

.FlagCountryCI {
  -o-object-position: -320px -35px;
  object-position: -320px -35px;
}

.FlagCountryCL {
  -o-object-position: -351px -35px;
  object-position: -351px -35px;
}

.FlagCountryCM {
  -o-object-position: -382px -35px;
  object-position: -382px -35px;
}

.FlagCountryCN {
  -o-object-position: -413px -35px;
  object-position: -413px -35px;
}

.FlagCountryCO {
  -o-object-position: -444px -35px;
  object-position: -444px -35px;
}

.FlagCountryCR {
  -o-object-position: -475px -35px;
  object-position: -475px -35px;
}

.FlagCountryCY {
  -o-object-position: -506px -35px;
  object-position: -506px -35px;
}

.FlagCountryCZ {
  -o-object-position: -10px -60px;
  object-position: -10px -60px;
}

.FlagCountryDE {
  -o-object-position: -41px -60px;
  object-position: -41px -60px;
}

.FlagCountryDJ {
  -o-object-position: -72px -60px;
  object-position: -72px -60px;
}

.FlagCountryDK {
  -o-object-position: -103px -60px;
  object-position: -103px -60px;
}

.FlagCountryDO {
  -o-object-position: -134px -60px;
  object-position: -134px -60px;
}

.FlagCountryDZ {
  -o-object-position: -165px -60px;
  object-position: -165px -60px;
}

.FlagCountryEC {
  -o-object-position: -196px -60px;
  object-position: -196px -60px;
}

.FlagCountryEE {
  -o-object-position: -227px -60px;
  object-position: -227px -60px;
}

.FlagCountryEG {
  -o-object-position: -258px -60px;
  object-position: -258px -60px;
}

.FlagCountryES {
  -o-object-position: -289px -60px;
  object-position: -289px -60px;
}

.FlagCountryET {
  -o-object-position: -320px -60px;
  object-position: -320px -60px;
}

.FlagCountryEU {
  -o-object-position: -351px -60px;
  object-position: -351px -60px;
}

.FlagCountryFI {
  -o-object-position: -382px -60px;
  object-position: -382px -60px;
}

.FlagCountryFO {
  -o-object-position: -413px -60px;
  object-position: -413px -60px;
}

.FlagCountryFR {
  -o-object-position: -444px -60px;
  object-position: -444px -60px;
}

.FlagCountryGA {
  -o-object-position: -475px -60px;
  object-position: -475px -60px;
}

.FlagCountryGB {
  -o-object-position: -506px -60px;
  object-position: -506px -60px;
}

.FlagCountryGH {
  -o-object-position: -10px -85px;
  object-position: -10px -85px;
}

.FlagCountryGI {
  -o-object-position: -41px -85px;
  object-position: -41px -85px;
}

.FlagCountryGL {
  -o-object-position: -72px -85px;
  object-position: -72px -85px;
}

.FlagCountryGM {
  -o-object-position: -103px -85px;
  object-position: -103px -85px;
}

.FlagCountryGR {
  -o-object-position: -134px -85px;
  object-position: -134px -85px;
}

.FlagCountryGT {
  -o-object-position: -165px -85px;
  object-position: -165px -85px;
}

.FlagCountryGU {
  -o-object-position: -196px -85px;
  object-position: -196px -85px;
}

.FlagCountryGY {
  -o-object-position: -227px -85px;
  object-position: -227px -85px;
}

.FlagCountryHK {
  -o-object-position: -258px -85px;
  object-position: -258px -85px;
}

.FlagCountryHN {
  -o-object-position: -289px -85px;
  object-position: -289px -85px;
}

.FlagCountryHR {
  -o-object-position: -320px -85px;
  object-position: -320px -85px;
}

.FlagCountryHT {
  -o-object-position: -351px -85px;
  object-position: -351px -85px;
}

.FlagCountryHU {
  -o-object-position: -382px -85px;
  object-position: -382px -85px;
}

.FlagCountryID {
  -o-object-position: -413px -85px;
  object-position: -413px -85px;
}

.FlagCountryIE {
  -o-object-position: -444px -85px;
  object-position: -444px -85px;
}

.FlagCountryIL {
  -o-object-position: -475px -85px;
  object-position: -475px -85px;
}

.FlagCountryIM {
  -o-object-position: -506px -85px;
  object-position: -506px -85px;
}

.FlagCountryIN {
  -o-object-position: -10px -110px;
  object-position: -10px -110px;
}

.FlagCountryIQ {
  -o-object-position: -41px -110px;
  object-position: -41px -110px;
}

.FlagCountryIR {
  -o-object-position: -72px -110px;
  object-position: -72px -110px;
}

.FlagCountryIS {
  -o-object-position: -103px -110px;
  object-position: -103px -110px;
}

.FlagCountryIT {
  -o-object-position: -134px -110px;
  object-position: -134px -110px;
}

.FlagCountryJE {
  -o-object-position: -165px -110px;
  object-position: -165px -110px;
}

.FlagCountryJM {
  -o-object-position: -196px -110px;
  object-position: -196px -110px;
}

.FlagCountryJO {
  -o-object-position: -227px -110px;
  object-position: -227px -110px;
}

.FlagCountryJP {
  -o-object-position: -258px -110px;
  object-position: -258px -110px;
}

.FlagCountryKE {
  -o-object-position: -289px -110px;
  object-position: -289px -110px;
}

.FlagCountryKG {
  -o-object-position: -320px -110px;
  object-position: -320px -110px;
}

.FlagCountryKH {
  -o-object-position: -351px -110px;
  object-position: -351px -110px;
}

.FlagCountryKN {
  -o-object-position: -382px -110px;
  object-position: -382px -110px;
}

.FlagCountryKP {
  -o-object-position: -413px -110px;
  object-position: -413px -110px;
}

.FlagCountryKR {
  -o-object-position: -444px -110px;
  object-position: -444px -110px;
}

.FlagCountryKW {
  -o-object-position: -475px -110px;
  object-position: -475px -110px;
}

.FlagCountryKZ {
  -o-object-position: -506px -110px;
  object-position: -506px -110px;
}

.FlagCountryLA {
  -o-object-position: -10px -135px;
  object-position: -10px -135px;
}

.FlagCountryLB {
  -o-object-position: -41px -135px;
  object-position: -41px -135px;
}

.FlagCountryLC {
  -o-object-position: -72px -135px;
  object-position: -72px -135px;
}

.FlagCountryLI {
  -o-object-position: -103px -135px;
  object-position: -103px -135px;
}

.FlagCountryLK {
  -o-object-position: -134px -135px;
  object-position: -134px -135px;
}

.FlagCountryLS {
  -o-object-position: -165px -135px;
  object-position: -165px -135px;
}

.FlagCountryLT {
  -o-object-position: -196px -135px;
  object-position: -196px -135px;
}

.FlagCountryLU {
  -o-object-position: -227px -135px;
  object-position: -227px -135px;
}

.FlagCountryLV {
  -o-object-position: -258px -135px;
  object-position: -258px -135px;
}

.FlagCountryMA {
  -o-object-position: -289px -135px;
  object-position: -289px -135px;
}

.FlagCountryMC {
  -o-object-position: -320px -135px;
  object-position: -320px -135px;
}

.FlagCountryMD {
  -o-object-position: -351px -135px;
  object-position: -351px -135px;
}

.FlagCountryMG {
  -o-object-position: -382px -135px;
  object-position: -382px -135px;
}

.FlagCountryMK {
  -o-object-position: -413px -135px;
  object-position: -413px -135px;
}

.FlagCountryML {
  -o-object-position: -444px -135px;
  object-position: -444px -135px;
}

.FlagCountryMM {
  -o-object-position: -475px -135px;
  object-position: -475px -135px;
}

.FlagCountryMN {
  -o-object-position: -506px -135px;
  object-position: -506px -135px;
}

.FlagCountryMO {
  -o-object-position: -10px -160px;
  object-position: -10px -160px;
}

.FlagCountryMT {
  -o-object-position: -41px -160px;
  object-position: -41px -160px;
}

.FlagCountryMU {
  -o-object-position: -72px -160px;
  object-position: -72px -160px;
}

.FlagCountryMX {
  -o-object-position: -103px -160px;
  object-position: -103px -160px;
}

.FlagCountryMY {
  -o-object-position: -134px -160px;
  object-position: -134px -160px;
}

.FlagCountryMZ {
  -o-object-position: -165px -160px;
  object-position: -165px -160px;
}

.FlagCountryNA {
  -o-object-position: -196px -160px;
  object-position: -196px -160px;
}

.FlagCountryNE {
  -o-object-position: -227px -160px;
  object-position: -227px -160px;
}

.FlagCountryNG {
  -o-object-position: -258px -160px;
  object-position: -258px -160px;
}

.FlagCountryNI {
  -o-object-position: -289px -160px;
  object-position: -289px -160px;
}

.FlagCountryNL {
  -o-object-position: -320px -160px;
  object-position: -320px -160px;
}

.FlagCountryNO {
  -o-object-position: -351px -160px;
  object-position: -351px -160px;
}

.FlagCountryNZ {
  -o-object-position: -382px -160px;
  object-position: -382px -160px;
}

.FlagCountryOM {
  -o-object-position: -413px -160px;
  object-position: -413px -160px;
}

.FlagCountryPA {
  -o-object-position: -444px -160px;
  object-position: -444px -160px;
}

.FlagCountryPE {
  -o-object-position: -475px -160px;
  object-position: -475px -160px;
}

.FlagCountryPG {
  -o-object-position: -506px -160px;
  object-position: -506px -160px;
}

.FlagCountryPH {
  -o-object-position: -10px -185px;
  object-position: -10px -185px;
}

.FlagCountryPK {
  -o-object-position: -41px -185px;
  object-position: -41px -185px;
}

.FlagCountryPL {
  -o-object-position: -72px -185px;
  object-position: -72px -185px;
}

.FlagCountryPR {
  -o-object-position: -103px -185px;
  object-position: -103px -185px;
}

.FlagCountryPS {
  -o-object-position: -134px -185px;
  object-position: -134px -185px;
}

.FlagCountryPT {
  -o-object-position: -165px -185px;
  object-position: -165px -185px;
}

.FlagCountryPY {
  -o-object-position: -196px -185px;
  object-position: -196px -185px;
}

.FlagCountryQA {
  -o-object-position: -227px -185px;
  object-position: -227px -185px;
}

.FlagCountryREMOTE {
  -o-object-position: -258px -185px;
  object-position: -258px -185px;
}

.FlagCountryRO {
  -o-object-position: -289px -185px;
  object-position: -289px -185px;
}

.FlagCountryRS {
  -o-object-position: -320px -185px;
  object-position: -320px -185px;
}

.FlagCountryRU {
  -o-object-position: -351px -185px;
  object-position: -351px -185px;
}

.FlagCountryRW {
  -o-object-position: -382px -185px;
  object-position: -382px -185px;
}

.FlagCountrySA {
  -o-object-position: -413px -185px;
  object-position: -413px -185px;
}

.FlagCountrySE {
  -o-object-position: -444px -185px;
  object-position: -444px -185px;
}

.FlagCountrySG {
  -o-object-position: -475px -185px;
  object-position: -475px -185px;
}

.FlagCountrySI {
  -o-object-position: -506px -185px;
  object-position: -506px -185px;
}

.FlagCountrySK {
  -o-object-position: -10px -210px;
  object-position: -10px -210px;
}

.FlagCountrySL {
  -o-object-position: -41px -210px;
  object-position: -41px -210px;
}

.FlagCountrySM {
  -o-object-position: -72px -210px;
  object-position: -72px -210px;
}

.FlagCountrySN {
  -o-object-position: -103px -210px;
  object-position: -103px -210px;
}

.FlagCountrySO {
  -o-object-position: -134px -210px;
  object-position: -134px -210px;
}

.FlagCountrySV {
  -o-object-position: -165px -210px;
  object-position: -165px -210px;
}

.FlagCountryTD {
  -o-object-position: -196px -210px;
  object-position: -196px -210px;
}

.FlagCountryTH {
  -o-object-position: -227px -210px;
  object-position: -227px -210px;
}

.FlagCountryTJ {
  -o-object-position: -258px -210px;
  object-position: -258px -210px;
}

.FlagCountryTL {
  -o-object-position: -289px -210px;
  object-position: -289px -210px;
}

.FlagCountryTN {
  -o-object-position: -320px -210px;
  object-position: -320px -210px;
}

.FlagCountryTR {
  -o-object-position: -351px -210px;
  object-position: -351px -210px;
}

.FlagCountryTT {
  -o-object-position: -382px -210px;
  object-position: -382px -210px;
}

.FlagCountryTW {
  -o-object-position: -413px -210px;
  object-position: -413px -210px;
}

.FlagCountryTZ {
  -o-object-position: -444px -210px;
  object-position: -444px -210px;
}

.FlagCountryUA {
  -o-object-position: -475px -210px;
  object-position: -475px -210px;
}

.FlagCountryUG {
  -o-object-position: -506px -210px;
  object-position: -506px -210px;
}

.FlagCountryUS {
  -o-object-position: -10px -235px;
  object-position: -10px -235px;
}

.FlagCountryUY {
  -o-object-position: -41px -235px;
  object-position: -41px -235px;
}

.FlagCountryUZ {
  -o-object-position: -72px -235px;
  object-position: -72px -235px;
}

.FlagCountryVE {
  -o-object-position: -103px -235px;
  object-position: -103px -235px;
}

.FlagCountryVN {
  -o-object-position: -134px -235px;
  object-position: -134px -235px;
}

.FlagCountryXX {
  -o-object-position: -165px -235px;
  object-position: -165px -235px;
}

.FlagCountryYE {
  -o-object-position: -196px -235px;
  object-position: -196px -235px;
}

.FlagCountryZA {
  -o-object-position: -227px -235px;
  object-position: -227px -235px;
}
