@import '../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  @media (--viewportMLarge) {
    padding: 0 36px;
  }
  &.headerSticky {
    & .logoLink {
      color: var(--marketplaceColor);
    }
    & .leftSection {
      & > select {
        filter: invert(0);
      }
      & .countryLabel {
        color: var(--colorGrey800);
      }
    }
    & .navLink {
      color: var(--colorGrey800);
      padding: 20px 8px;
      @media (--viewportLarge) {
        padding: 30px 16px;
      }
      &:hover {
        color: var(--marketplaceColorDark);
      }
    }
  }
  &.whiteBgNav {
    & .logoLink {
      color: var(--marketplaceColor);
    }
    & .leftSection {
      & > select {
        filter: invert(0);
      }
      & .countryLabel {
        color: var(--colorGrey800);
      }
    }
    & .navLink {
      color: var(--colorGrey800);
      padding: 20px 8px;
      @media (--viewportLarge) {
        padding: 30px 16px;
      }
      &:hover {
        color: var(--marketplaceColorDark);
      }
    }
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);
  color: var(--colorWhite);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  @media (--viewportLargeWithPaddings) {
    font-size: 20px;
  }
}
.logoLink:hover {
  border-radius: 0;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--marketplaceColor);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 25px;
  right: 0px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
  @media (--viewportLarge) {
    right: 10px;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
  & .avatar {
    & > span {
      font-size: 16px;
    }
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-radius: 0;
    text-decoration: none;
  }
}

.signup,
.login {
  composes: textSmall from global;

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyle);
}

.profileSettingsLink,
.yourListingsLink {
  composes: marketplaceSmallFontStyles from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 10px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyle);
  font-size: 18px;

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyle);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}
.navLink {
  position: relative;
  color: var(--colorWhite);
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 20px;
  padding: 30px 8px;
  display: inline-block;
  transition: var(--transitionStyle);
  @media (--viewportLarge) {
    font-size: 15px;
    line-height: 20px;
    padding: 30px 16px;
  }
  @media (--viewportLargeWithPaddings) {
    font-size: 16px;
    line-height: 20px;
  }
  &:hover {
    transition: var(--transitionStyle);
    color: var(--marketplaceColorDark);
    text-decoration: none;
    cursor: pointer;
  }
}
.leftNavigation {
  display: flex;
  align-items: center;
  & .leftSection {
    display: flex;
    align-items: center;

    & > select {
      cursor: pointer;
      padding: 0px 0px 3px 34px !important;
      width: 1% !important;
      border: none !important;
      font-weight: 700 !important;
      background-color: transparent;
      background-image: url('https://cms-assets.boise.vacasa.com/svg/icon-nav-chevron-midnight.70a580c289aa.svg') !important;
      filter: invert(1);
    }
    & .countryModal {
      & .countryIcon {
        cursor: pointer;
        &:hover {
          & > svg {
            & path {
              stroke: var(--marketplaceColor);
            }
          }
        }
      }
    }
  }
}
& .rightSection {
  & .becomeHostBtn {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    border: none;
    transition: var(--transitionStyle);
    font-weight: var(--fontWeightSemiBold);
    font-size: 15px;
    line-height: 20px;
    padding: 8px 16px;
    border-radius: 10px;
    display: inline-block;
    margin-left: 20px;
    position: relative !important;
    align-self: center;
    @media (--viewportLargeWithPaddings) {
      font-size: 16px;
      line-height: 20px;
      padding: 10px 20px;
    }
    &:hover {
      transition: var(--transitionStyle);
      text-decoration: none;
      background-color: var(--marketplaceColorDark);
      color: var(--colorWhite);
    }
  }
}

.rightSection {
  display: flex;
  align-items: center;
}
.listingPageNav {
  /* max-width: calc(var(--contentMaxWidth) + 72px); */
  width: 100%;
  margin: 0 auto;
}

.chooseCountry {
  & > h1 {
    font-size: 25px;
    line-height: 110%;
    margin: 0 0 15px 0;
    padding: 0;
    color: var(--colorGrey800);
  }
  & > p {
    color: var(--colorGrey600);
    font-size: 18px;
    line-height: 130%;
    padding: 0;
    margin: 0;
  }
}

.countryLabel {
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorWhite);
}
