@import '../../styles/customMediaQueries.css';

.pageTitle {
  text-align: center;
  font-size: 26px;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: contain;
  margin: 0px 0 40px;

  @media (max-width: 767px) {
    height: 100%;
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.subtitle {
  composes: marketplaceH3FontStyles from global;
  margin-top: 32px;
  margin-bottom: 16px;
}

.content {
  /* text-align: center; */
  & h1 {
    color: #4461ac;
    text-decoration: underline;
    margin-top: 0;
  }
  & h4 {
    /* color: #4461ac; */
    text-decoration: underline;
  }
}

.featuresComparison {
  margin: 30px 0 0;
  width: 100%;
  background-color: var(--colorWhite);
  overflow-x: auto;
  & .featuretable {
    width: 100%;
    border: solid 1px var(--colorGrey100);
    min-width: 600px;
    & tr {
      &:not(:last-child) {
        border-bottom: solid 1px var(--colorGrey100);
      }
      & > * {
        padding: 12px 20px;
        text-align: left;
        width: calc(100% / 3);
        &:not(:last-child) {
          border-right: solid 1px var(--colorGrey100);
        }
        & > svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    & thead {
      & > tr {
        & > td {
          border-right: solid 1px var(--colorWhite);
          background-color: var(--marketplaceColor);
          font-size: 20px;
          font-weight: var(--fontWeightBold);
          color: var(--colorWhite);
        }
      }
    }
  }
}
