@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  &:not(:last-child) {
    margin-right: 24px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;
  color: var(--colorGrey700);
  font-size: 16px;
  line-height: 100%;
  font-weight: var(--fontWeightRegular);
  border: solid 1px var(--colorGrey100);
  background-color: var(--colorWhite);
  border-radius: 4px;
  padding: 10px 20px;
  min-height: 45px;
  @media (--viewportMedium) {
    font-size: 20px;
  }
  & > span {
    &:not(:last-child) {
      margin-top: -5px;
    }
  }
  &:hover {
    text-decoration: none;
    color: var(--colorBlack);
    border: solid 1px var(--colorGrey200);
  }

  /* &::after {
    content: '';
    background-color: var(--marketplaceColor);
    width: 0;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
  } */
}

.selectedLink {
  color: var(--colorWhite);
  background-color: var(--marketplaceColor);
  border: solid 1px var(--marketplaceColor);
  font-size: 16px;
  line-height: 100%;
  @media (--viewportMedium) {
    font-size: 20px;
  }
  &:hover {
    text-decoration: none;
    color: var(--colorWhite);
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}
