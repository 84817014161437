.uploadButton {
  background: #6fc8cc;
  padding: 16px 92px;
  border: none;
  color: white;
  cursor: pointer;
}
.dropzone {
  border: solid red;
}
