@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.bottomWrapper {
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 20px;
  color: var(--colorBlack);

  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 20px;
  }
  @media (--viewportLarge) {
    font-size: 16px;
    line-height: 20px;
  }
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}
.loginFormHead {
  text-align: center;
  margin-bottom: 25px;
  @media (--viewportLarge) {
    margin-bottom: 34px;
  }
  & > h2 {
    font-weight: var(--fontWeightBold);
    font-size: 20px;
    line-height: 28px;
    margin: 18px 0 9px 0px;
    padding: 0;
    color: var(--colorBlack);
    @media (--viewportLarge) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  & > p {
    font-weight: var(--fontWeightRegular);
    font-size: 15px;
    line-height: 20px;
    color: var(--marketplaceTextColor);
    margin: 0;
    padding: 0;
    @media (--viewportLarge) {
      font-size: 16px;
      line-height: 23px;
    }
  }
}
.SocialMediaLogin {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (--viewportLarge) {
    margin-bottom: 20px;
  }
  & > a {
    width: calc(100% / 2 - 6px);
    margin-right: 12px;
    border: 1px solid #b8b2b3;
    border-radius: 9px;
    padding: 10px 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: var(--transitionStyle);
    @media (--viewportLarge) {
      padding: 12px 20px;
      width: calc(100% / 2 - 11px);
      margin-right: 22px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      border-color: #1f6de2;
      transition: var(--transitionStyle);
      &:last-child {
        border-color: #e8483e;
      }
    }
  }
}
.orText {
  margin: 10px 0;
  width: 100%;
  text-align: center;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceTextColor);
  text-transform: uppercase;
  @media (--viewportLarge) {
    margin: 12px 0;
  }
}
label {
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 20px;
  color: var(--colorBlack);
  padding: 0;
  margin: 0 0 6px 0;
  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 20px;
  }
  @media (--viewportLarge) {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 8px 0;
  }
}
input {
  font-weight: var(--fontWeightRegular);
  font-size: 15px;
  line-height: 20px;
  color: var(--colorBlack);
  height: 50px;
  border: 1px solid #b8b2b3;
  border-radius: 9px;
  @media (--viewportLarge) {
    font-size: 16px;
    line-height: 20px;
    height: 50px;
  }
  &::placeholder {
    color: var(--colorBlack);
    opacity: 0.4;
  }
  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    border-color: var(--colorGrey500);
  }
}
.formRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
  &.halfWidth {
    flex-direction: row;
    width: 100%;
    align-items: center;

    & .formFld {
      font-weight: var(--fontWeightRegular);
      font-size: 14px;
      line-height: 20px;
      color: var(--colorBlack);
      @media (--viewportMedium) {
        font-size: 15px;
        line-height: 20px;
      }
      @media (--viewportLarge) {
        font-size: 18px;
        line-height: 23px;
      }
      &:last-child {
        text-align: right;
      }
      & > span {
        & > label {
          margin-bottom: 0;
          & > span {
            font-weight: var(--fontWeightRegular);
            font-size: 14px;
            line-height: 20px;
            color: var(--colorBlack);
            padding: 0;
            margin-bottom: 0;
            @media (--viewportMedium) {
              font-size: 15px;
              line-height: 20px;
            }
            @media (--viewportLarge) {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  & .formFld {
    width: 100%;
  }
}
.haveAccount {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  font-weight: var(--fontWeightRegular);
  font-size: 15px;
  line-height: 20px;
  color: var(--colorBlack);
  @media (--viewportLarge) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 22px;
  }
  & > a {
    color: var(--marketplaceColor);
    &:hover {
      color: var(--marketplaceColorDark);
    }
  }
}

.error {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
  color: red;
}

.signupUserPage{
  cursor: pointer;
  color: var(--marketplaceColor);
}