@import '../../../styles/customMediaQueries.css';
.accordion {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }
  & .accordionItem {
    background: var(--colorWhite);
    border: 1px solid #ede4e6;
    border-radius: 14px;
    padding: 20px;
    align-self: flex-start;
    @media (--viewportLarge) {
      padding: 20px 30px;
    }
    &:first-child {
      @media (--viewportLarge) {
        grid-column: 1 / span 2;
      }
    }
    & .accordionHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      & > h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        text-transform: capitalize;
        margin: 0 0 0px 0;
        padding: 0;
        color: #1e1e1e;
        @media (--viewportMedium) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
    & .accordionContent {
      margin: 10px 0 0px 0;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      color: #050102;
      padding: 0;
      @media (--viewportMedium) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
