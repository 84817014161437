@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}
svg {
  fill: none;
}
.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    /* stroke: var(--marketplaceColor); */
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--colorSuccess);
  }

  /* disabled version */
  &:hover + label .boxDisabled,
  &:focus + label .boxDisabled,
  &:checked + label .boxDisabled {
    stroke: var(--colorGrey300);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    /* stroke: var(--marketplaceColor); */
    stroke-width: 1px;
  }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    stroke: var(--colorSuccess);
    stroke-width: 1px;
  }

  /* Display the disabled when checked */
  &:checked + label .checkedDisabled {
    display: inline;
    stroke: var(--colorGrey300);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--colorBlack);
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxWrapper {
  height: 32px;
  margin-top: -1px;
  margin-right: 8px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 24px;
  }
}

.checked {
  display: none;
  /* fill: var(--marketplaceColor); */
}
.checkedSuccess {
  display: none;
  fill: var(--colorSuccess);
}

.checkedDisabled {
  display: none;
  fill: var(--colorBlack);
}

.boxDisabled,
.boxSuccess,
.box {
  stroke: var(--marketplaceColor);
}

.text {
}
.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);
  padding: 0;
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
