@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.bottomWrapper {
}
label {
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 20px;
  color: var(--colorBlack);
  padding: 0;
  margin: 0 0 6px 0;
  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 20px;
  }
  @media (--viewportLarge) {
    font-size: 18px;
    line-height: 23px;
    margin: 0 0 10px 0;
  }
}
input {
  font-weight: var(--fontWeightRegular);
  font-size: 15px;
  line-height: 20px;
  color: var(--colorBlack);
  height: 50px;
  border: 1px solid #b8b2b3;
  border-radius: 9px;
  @media (--viewportLarge) {
    font-size: 18px;
    line-height: 23px;
    height: 58px;
  }
  &::placeholder {
    color: var(--colorBlack);
    opacity: 1;
  }
  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    border-color: var(--colorGrey500);
  }
}
.formRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &.halfWidth {
    flex-direction: row;
    width: 100%;
    align-items: flex-start;

    & .formFld {
      font-weight: var(--fontWeightRegular);
      font-size: 14px;
      line-height: 20px;
      color: var(--colorBlack);
      width: calc(100% / 2 - 12px);
      margin-right: 24px;
      @media (--viewportMedium) {
        font-size: 15px;
        line-height: 20px;
      }
      @media (--viewportLarge) {
        font-size: 18px;
        line-height: 23px;
      }
      &:last-child {
        margin-right: 0;
      }
      & > span {
        & > label {
          margin-bottom: 0;
          & > span {
            font-weight: var(--fontWeightRegular);
            font-size: 14px;
            line-height: 20px;
            color: var(--colorBlack);
            padding: 0;
            margin-bottom: 0;
            @media (--viewportMedium) {
              font-size: 15px;
              line-height: 20px;
            }
            @media (--viewportLarge) {
              font-size: 18px;
              line-height: 23px;
            }
          }
        }
      }
    }
  }
  & .formFld {
    width: 100%;
  }
}

.passwordError {
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  color: red;
}
