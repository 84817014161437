@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: var(--colorWhite);
  overflow: hidden;
  transition: var(--transitionStyle);
  border: solid 1px var(--colorGrey100);
  position: relative;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transition: var(--transitionStyle);
    box-shadow: 0px 4px 24px rgba(255, 115, 140, 0.29);
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  z-index: 0;
  @media (--viewportMedium) {
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 12px;
  padding-top: 5px;
  @media (min-width: 1600px) {
    padding: 16px;
    padding-top: 5px;
  }
  & .priceSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .featured {
      & .tag {
        background-color: var(--marketplaceColor);
        color: var(--colorWhite);
        font-weight: var(--fontWeightSemiBold);
        font-size: 14px;
        line-height: 16px;
        padding: 2px 10px;
        border-radius: 4px;
      }
    }
    & .price {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-shrink: 0;
      margin-bottom: 4px;
      & .priceValue {
        font-weight: var(--fontWeightSemiBold);
        color: var(--marketplaceBlackColor);
        font-size: 16px;
        line-height: 20px;
        @media (--viewportLargeWithPaddings) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      & .perUnit {
        font-weight: var(--fontWeightRegular);
        color: var(--marketplaceBlackColor);
        display: inline-flex;
        align-items: center;
        margin-left: 2px;
        font-size: 13px;
        line-height: 16px;
        text-transform: capitalize;
      }
    }
  }
}
.separator {
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  height: 1px;
  margin: 10px 0;
}
.mainInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  text-overflow: ellipsis;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 20px;
  color: #2f1e20;
  margin: 0 0 4px 0;
  text-transform: capitalize;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: inline-block;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  @media (--viewportLargeWithPaddings) {
    font-size: 18px;
    line-height: 24px;
  }
}
.description {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 0;
  color: #8c8385;
  text-align: left;
  @media (--viewportLargeWithPaddings) {
    font-size: 15px;
    line-height: 20px;
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.userProfile {
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff;
  overflow: hidden;
  border-radius: 100px;
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 12px;
  @media (--viewportMedium) {
    top: 22px;
    left: 22px;
    width: 54px;
    height: 54px;
  }
  & > img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.otherInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .infoBlock {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--marketplaceBlackColor);

    & > svg {
      margin-right: 6px;
      width: 18px;
      @media (min-width: 1800px) {
        width: 20px;
      }
    }
  }
}
.reviews {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .reviewsLeft {
    display: flex;
    flex-direction: column;
    align-items: start;
    & > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 7px 0;
      padding: 0;
      color: var(--marketplaceBlackColor);
    }
    & .rating {
      display: flex;
      align-items: center;
      & > svg {
        margin-right: 7px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  & .reviewsRight {
    width: 30px;
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      background-color: var(--colorGrey50);
      box-shadow: 0px 4px 4px rgba(255, 115, 140, 0.29);
      & > svg {
        & circle {
          fill: var(--marketplaceColor);
        }
      }
    }
  }
}
.favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: var(--colorWhite);
  border-radius: 100px;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: var(--boxShadowBreakdown);
  & > svg {
    width: 22px;
    height: 22px;
  }
}
