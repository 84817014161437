@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 24px;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > select {
    cursor: pointer;
    width: 50% !important;
    border: none !important;
    margin-left: 22px;
    position: relative;
    bottom: 17px;
    font-size: 16px;
  }
  & > a {
    font-size: 16px;
    margin-bottom: 24px;
    display: inline-block;
    width: 100%;
    &.policyLink {
      & > svg {
        width: 30px;
        height: 30px;
        margin-left: -2px;
        margin-right: 12px;
      }
    }
    & > svg {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 14px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  & > span {
    font-size: 30px;
  }
}

.greeting {
  margin-bottom: 1px;
  margin-top: 16px;
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  composes: h2 from global;
  color: var(--colorBlack);
  position: relative;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  color: var(--colorBlack);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  text-decoration: none;
  white-space: nowrap;
  background-color: var(--colorWhite);
  color: var(--marketplaceColor);
  border: solid 1px var(--marketplaceColor);
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: var(--fontWeightSemiBold);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    border: solid 1px var(--marketplaceColor);
  }
}

.authenticationGreeting {
  font-size: 24px;
  margin: 24px 0;
  color: var(--colorBlack);
  font-weight: var(--fontWeightSemiBold);
  & > span {
    font-size: 20px;
    line-height: 130%;
    margin-top: 5px;
    display: inline-block;
    color: var(--colorGrey600);
    font-weight: var(--fontWeightRegular);
  }
}
.preloginBtns {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  @media (--viewportSmall) {
    flex-direction: row;
  }
  & > * {
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 14px;
      @media (--viewportSmall) {
        margin-bottom: 0px;
        margin-right: 24px;
      }
    }
  }
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
  display: inline-block;
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  border: solid 1px var(--marketplaceColor);
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: var(--fontWeightSemiBold);

  &:hover {
    background-color: var(--colorWhite);
    color: var(--marketplaceColor);
    border: solid 1px var(--marketplaceColor);
  }
}
.loginLink {
  composes: buttonPrimary from global;
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}
