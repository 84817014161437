@import '../../../styles/customMediaQueries.css';

.root {
  margin-bottom: 44px;
  & ul {
    & > li {
      padding: 0;
      & > span {
        & > label {
          margin-bottom: 0;
          & > span {
            font-weight: var(--fontWeightRegular);
            font-size: 14px;
            line-height: 20px;
            color: var(--colorBlack);
            padding: 0;
            margin-bottom: 0;
            @media (--viewportMedium) {
              font-size: 15px;
              line-height: 20px;
            }
            @media (--viewportLarge) {
              font-size: 18px;
              line-height: 23px;
            }
          }
        }
      }
    }
  }
}

.finePrint {
  text-align: left;
}

.privacyLink,
.termsLink {
  color: var(--colorBlack);
  font-weight: var(--fontWeightMedium);

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: underline;
    cursor: pointer;
  }
}
