@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
}
.sticky {
}
.sectionTitle {
  composes: h2 from global;
}
.sectionSubHeading {
  composes: sectionSubHeading from global;
}
.contentContainer {
  composes: marketplaceModalRootStyles from global;
  height: calc(100vh - 60px);

  @media (--viewportMedium) {
    height: unset;
  }
}

.content {
  background-color: var(--colorWhite);
  padding: 24px;
  border-radius: var(--borderRadiusLarge);
  @media (--viewportMedium) {
    padding: 43px 33px;
  }
  & > h2 {
    font-weight: var(--fontWeightBold);
    font-size: 24px;
    line-height: 38px;
    color: var(--colorBlack);
    margin-bottom: 31px;
    @media (--viewportMedium) {
      font-size: 30px;
    }
  }
  & .signupForm {
  }
}
.registerPage {
  padding: 0 16px;
  @media (--viewportMedium) {
    padding: 0 24px;
  }
  & .contentWidth {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
  }
}
.registrationFeatureSec {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 70px;
  }
  @media (--viewportLarge) {
    margin-top: 100px;
  }
  & .leftSide {
    width: 100%;
    @media (--viewportMedium) {
      width: 50%;
    }
    & .featureBlock {
      height: 100%;
      margin-bottom: 30px;
      @media (--viewportMedium) {
        margin-bottom: 0;
      }
      & > img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }
  & .rightSide {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (--viewportMedium) {
      width: calc(50% - 30px);
      margin-left: 30px;
    }
    & .featureBlock {
      margin-bottom: 30px;
      height: 350px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  & .featureBlock {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    transition: var(--transitionStyle);

    &:hover {
      cursor: pointer;
      transition: var(--transitionStyle);
      & > img {
        transform: scale(1.05);
        transition: var(--transitionStyle);
      }
      & .featureTitle {
        color: var(--marketplaceColor);
        transition: var(--transitionStyle);
      }
    }
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180.65deg, rgba(3, 3, 3, 0) 52.91%, rgba(0, 0, 0, 0.61) 86.14%);
    }
    & > img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      transition: var(--transitionStyle);
      transform: scale(1);
    }
    & .featureTitle {
      position: absolute;
      z-index: 1;
      padding: 28px;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: var(--colorWhite);
      transition: var(--transitionStyle);
      @media (min-width: 1280px) {
        padding: 30px;
        font-size: 30px;
        line-height: 34px;
      }
    }
  }
}
.becomeHostSec {
  margin-top: 50px;
  @media (--viewportMedium) {
    margin-top: 80px;
  }
  @media (--viewportLarge) {
    margin-top: 90px;
  }
  @media (--viewportLargeWithPaddings) {
    margin-top: 100px;
  }
  & > h2 {
    text-align: center;
  }
  & .becomeHostBlock {
    display: flex;
    margin-top: 40px;
    margin-bottom: 50px;
    flex-direction: column;
    @media (--viewportMedium) {
      flex-direction: row;
    }
    @media (--viewportLarge) {
      margin-bottom: 100px;
      margin-top: 50px;
    }
    @media (--viewportLargeWithPaddings) {
      margin-bottom: 100px;
      margin-top: 70px;
    }
    &:nth-child(odd) {
      & .becomeHostBlockInfo {
        padding-left: 0px;
        @media (--viewportMedium) {
          padding-left: 30px;
        }
        @media (--viewportLarge) {
          padding-left: 40px;
        }
        @media (--viewportLargeWithPaddings) {
          padding-left: 90px;
        }
      }
    }
    &:nth-child(even) {
      & .becomeHostBlockInfo {
        padding-right: 20px;
        @media (--viewportMedium) {
          padding-right: 30px;
        }
        @media (--viewportLarge) {
          padding-right: 40px;
        }
        @media (--viewportLargeWithPaddings) {
          padding-right: 90px;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    & .becomeHostBlockInfo {
      width: 100%;
      order: 1;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media (--viewportMedium) {
        width: 50%;
        order: initial;
        margin-bottom: 0px;
      }
      & .blockCount {
        width: 50px;
        height: 50px;
        padding: 4px;
        margin-bottom: 16px;
        border: 1px dashed var(--marketplaceColor);
        border-radius: 100%;
        overflow: hidden;

        @media (--viewportLarge) {
          width: 80px;
          height: 80px;
          padding: 8px;
          margin-bottom: 26px;
        }
        & > span {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: var(--fontWeightBold);
          font-size: 16px;
          line-height: 20px;
          background-color: var(--marketplaceColor);
          color: var(--colorWhite);
          border-radius: 100%;

          @media (--viewportMedium) {
            font-size: 18px;
            line-height: 24px;
          }
          @media (--viewportLarge) {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
      & > h2 {
        font-weight: var(--fontWeightBold);
        font-size: 20px;
        line-height: 25px;
        margin: 0 0 10px 0;
        padding: 0;
        color: var(--colorBlack);
        @media (--viewportMedium) {
          font-size: 24px;
          line-height: 28px;
          margin: 0 0 14px 0;
        }
        @media (--viewportLarge) {
          font-size: 28px;
          line-height: 34px;
          margin: 0 0 15px 0;
        }
      }
      & > p {
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        padding: 0;
        color: var(--colorBlack);
        @media (--viewportMedium) {
          font-size: 16px;
          line-height: 24px;
        }
        @media (--viewportLarge) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
    & .becomeHostBlockImg {
      border-radius: 6px;
      width: 100%;
      order: 2;
      @media (--viewportMedium) {
        width: 50%;
        order: initial;
      }
      & > img {
        max-width: 100%;
      }
    }
  }
}

.keepUnderControlSec {
  margin-top: 50px;
  @media (--viewportMedium) {
    margin-top: 60px;
  }
  @media (--viewportLarge) {
    margin-top: 70px;
  }
  @media (--viewportLargeWithPaddings) {
    margin-top: 80px;
  }
  & .contentWidth {
    background: #f9f9f9;
    border-radius: 40px;
    width: 100%;
    max-width: 100%;
    padding: 50px 16px;
    @media (--viewportMedium) {
      padding: 60px 24px;
    }
    @media (--viewportLarge) {
      padding: 70px 24px;
    }

    @media (--viewportLargeWithPaddings) {
      padding: 80px 24px;
    }
    & > h2 {
      text-align: center;
      margin-bottom: 50px;
      @media (--viewportMedium) {
        margin-bottom: 60px;
      }
      @media (--viewportLarge) {
        margin-bottom: 70px;
      }
      @media (--viewportLargeWithPaddings) {
        margin-bottom: 80px;
      }
    }
  }
  & .keepControlSec {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    @media (--viewportLarge) {
      flex-direction: row;
    }
    & .keepControlBlock {
      width: 100%;
      margin-bottom: 80px;
      background: var(--colorWhite);
      border: 1px solid var(--colorWhite);
      border-radius: var(--borderRadiusLarge);
      padding: 30px;
      transition: var(--transitionStyle);
      @media (--viewportLarge) {
        width: calc(100% / 3 - 10px);
        margin-right: 20px;
        margin-bottom: 0px;
      }
      @media (--viewportLargeWithPaddings) {
        width: calc(100% / 3 - 14px);
        margin-right: 28px;
        padding: 50px;
      }
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
      &:hover {
        background-color: var(--marketplaceColor);
        transition: var(--transitionStyle);

        & .controlIcon {
          background-color: var(--marketplaceColor);
          & > svg {
            & path {
              fill: var(--colorWhite);
              transition: var(--transitionStyle);
            }
          }
        }
        & > h2,
        & > p {
          color: var(--colorWhite);
          transition: var(--transitionStyle);
        }
      }
      & .controlIcon {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        background: var(--colorWhite);
        margin-top: -70px;
        transition: var(--transitionStyle);
        @media (--viewportLargeWithPaddings) {
          margin-top: -90px;
        }
      }
      & > h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin: 20px 0 10px 0;
        padding: 0;
        color: #1e1e1e;
        transition: var(--transitionStyle);
        @media (--viewportMedium) {
          font-size: 20px;
          line-height: 24px;
        }
      }
      & > p {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        padding: 0;
        color: #050102;
        transition: var(--transitionStyle);
        @media (--viewportMedium) {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }
}

.findComfortableplace {
  position: relative;
  width: 100%;
  z-index: 0;
  margin-top: 50px;
  @media (--viewportMedium) {
    margin-top: 60px;
  }
  @media (--viewportLarge) {
    margin-top: 70px;
  }
  @media (--viewportLargeWithPaddings) {
    margin-top: 80px;
  }
  & .amazingPlacesSection {
    background-image: url('../../assets/list-your-places.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    overflow: hidden;
    border-radius: 40px;
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #2c030a;
      opacity: 0.4;
      content: '';
      z-index: 0;
      @media (--viewportMedium) {
        opacity: 0.2;
      }
    }
    & .bannerContent {
      text-align: left;
      max-width: 100%;
      width: 100%;
      padding: 50px 30px;
      color: var(--colorWhite);
      position: relative;
      z-index: 1;
      @media (--viewportMedium) {
        max-width: 70%;
        padding: 60px 30px;
      }
      @media (--viewportLarge) {
        padding: 70px 40px;
      }
      @media (min-width: 1440px) {
        padding: 80px 50px;
        max-width: 50%;
      }
      & > h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 6px 0;
        padding: 0;
        color: var(--colorWhite);
        @media (--viewportLarge) {
          margin: 0 0 13px 0;
          font-size: 28px;
          line-height: 38px;
        }
        @media (--viewportLargeWithPaddings) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      & > h2 {
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        margin: 0 0 10px 0;
        padding: 0;
        color: var(--colorWhite);
        @media (--viewportLarge) {
          font-size: 40px;
          line-height: 58px;
          margin: 0 0 12px 0;
        }
        @media (--viewportLargeWithPaddings) {
          font-size: 45px;
          line-height: 58px;
          margin: 0 0 15px 0;
        }
      }
      & > p {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 20px 0;
        padding: 0;
        color: var(--colorWhite);
        @media (--viewportLargeWithPaddings) {
          font-size: 20px;
          line-height: 26px;
          margin: 0 0 30px 0;
        }
      }
      & .bookNowBtn {
        background-color: var(--marketplaceColor);
        color: var(--colorWhite);
        border: none;
        transition: var(--transitionStyle);
        font-weight: var(--fontWeightMedium);
        font-size: 16px;
        line-height: 20px;
        padding: 8px 16px;
        border-radius: 10px;
        display: inline-block;
        cursor: pointer;
        @media (--viewportLarge) {
          font-size: 18px;
          line-height: 24px;
          padding: 8px 30px;
        }
        &:hover {
          transition: var(--transitionStyle);
          text-decoration: none;
          background-color: var(--marketplaceColorDark);
          color: var(--colorWhite);
        }
      }
    }
  }
}
.faqSection {
  margin-top: 50px;
  @media (--viewportMedium) {
    margin-top: 60px;
  }
  @media (--viewportLarge) {
    margin-top: 70px;
  }
  @media (--viewportLargeWithPaddings) {
    margin-top: 80px;
  }
  & .contentWidth {
    & > h2 {
      text-align: center;
      margin-bottom: 30px;
      @media (--viewportMedium) {
        margin-bottom: 40px;
      }
      @media (--viewportLarge) {
        margin-bottom: 50px;
      }
    }
  }
}

/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.tab {
  composes: marketplaceModalTitleStyles from global;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 0;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.signupForm {
  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px;

  @media (--viewportMedium) {
    flex-basis: 410px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.privacyWrapper,
.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
  & > section {
    padding-top: 0;
    & > div {
      padding: 0;
    }
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  composes: marketplaceModalCloseStyles from global;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

/* Helper links */
.modalHelperLink {
  composes: a from global;
  composes: marketplaceModalHelperLink from global;
  border-radius: 5px;
  padding: 5px 8px;
  background: #3e5ba6;
  color: white;
  margin-bottom: 10px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  composes: marketplaceModalPasswordMargins from global;
  margin-bottom: 24px;
  margin-top: 0;
  color: var(--colorFail);
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--colorBlack);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 6px;
  }
}

.confirmInfoText {
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.socialButtonWrapper {
  margin-bottom: 6px;
  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--colorWhite);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: 34px;
    margin: 15px 0;
  }
}

.socialButtonsOrText {
  background-color: var(--colorWhite);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.error {
  font-weight: 700;
  font-size: 15px;
  color: red;
}
