.renderCalendarInfo {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  margin-bottom: 0px;

  @media only screen and (max-width: 768px) {
    display: grid;
  }

  & .minimumStay {
    position: relative;
    top: 5px;
    left: 14px;
    font-weight: 700;

    @media only screen and (max-width: 768px) {
      bottom: 5px;
      top: 0;
      left: 6px;
    }
  }

  & .calendarActionButtons {
    & > span {
      cursor: pointer;
      text-decoration: underline;
      margin-right: 14px;
      font-weight: 600;
      position: absolute;
      right: 104px;
      bottom: 16px;
    }

    & > button {
      cursor: pointer;
      margin-bottom: 12px;
      background: black;
      color: white;
      border: 1px solid;
      border-radius: 6px;
      padding: 4px 26px;
    }
  }
}
