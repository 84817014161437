@import '../../styles/customMediaQueries.css';

.customRangeSlider {
  border-bottom: solid 2px var(--colorGrey200);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50px;
  gap: 5px;
  & > div {
    height: 100%;
    width: calc(100% / 50);
    background-color: var(--colorBlack);
    display: inline-block;
    &:first-child,
    &:last-child {
      height: 5%;
    }
    &:nth-child(2),
    &:nth-last-child(2) {
      height: 10%;
    }
    &:nth-child(3),
    &:nth-last-child(3) {
      height: 80%;
    }
    &:nth-child(4),
    &:nth-last-child(4) {
      height: 30%;
    }
    &:nth-child(5),
    &:nth-last-child(5) {
      height: 40%;
    }
    &:nth-child(6),
    &:nth-last-child(6) {
      height: 50%;
    }
    &:nth-child(7),
    &:nth-last-child(7) {
      height: 60%;
    }
    &:nth-child(8),
    &:nth-last-child(8) {
      height: 100%;
    }
    &:nth-child(9),
    &:nth-last-child(9) {
      height: 50%;
    }
    &:nth-child(10),
    &:nth-last-child(10) {
      height: 60%;
    }
    &:nth-child(11),
    &:nth-last-child(11) {
      height: 20%;
    }
    &:nth-child(12),
    &:nth-last-child(12) {
      height: 30%;
    }
    &:nth-child(13),
    &:nth-last-child(13) {
      height: 60%;
    }
    &:nth-child(14),
    &:nth-last-child(14) {
      height: 10%;
    }
    &:nth-child(15),
    &:nth-last-child(15) {
      height: 100%;
    }
    &:nth-child(16),
    &:nth-last-child(16) {
      height: 20%;
    }
    &:nth-child(17),
    &:nth-last-child(17) {
      height: 60%;
    }
    &:nth-child(18),
    &:nth-last-child(18) {
      height: 40%;
    }
    &:nth-child(19),
    &:nth-last-child(19) {
      height: 50%;
    }
    &:nth-child(20),
    &:nth-last-child(20) {
      height: 70%;
    }
    &:nth-child(21),
    &:nth-last-child(21) {
      height: 50%;
    }
    &:nth-child(22),
    &:nth-last-child(22) {
      height: 80%;
    }
    &:nth-child(23),
    &:nth-last-child(23) {
      height: 40%;
    }
    &:nth-child(24),
    &:nth-last-child(24) {
      height: 50%;
    }
  }
}
