.root {
  position: relative;
  width: 100%;
  height: 100%;
  & > div:last-child {
    & div {
      margin-left: 5px;
      background-image: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5 1L1 5L5 9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 52%;
      background-position-y: 6px;
    }
  }
}

.track {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: -4px;
  margin-left: -8px;
  /* display: none; */
  /* Layout */
  width: 100%;
  height: 8px;
  padding: 0 10px;
  box-sizing: content-box;
  background-color: var(--matterColorNegative);
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.range {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  height: 8px;
  background-color: var(--marketplaceColor);
  /* display: none; */
  /* width: 250px !important; */
}

.towerMainDiv {
  display: flex;
  align-items: flex-end;
  /* margin-top: 0px !important;
  top: 19px !important;
  -webkit-box-align: end !important;
  -webkit-box-pack: justify !important;
  position: relative !important;
  top: 6px !important;
  margin: 24px 2px 6px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-end !important; */
}

.fadedTower {
  background-color: rgb(221, 221, 221);
  flex: 1 1 0%;
  margin-right: 1px;
  /* background-color: rgb(221, 221, 221) !important;
  display: inline-block !important;
  border-top-left-radius: 1px !important;
  border-top-right-radius: 1px !important;
  flex: 1 1 0% !important;
  margin-right: 1px !important; */
}

.highlightedTower {
  /* display: inline-block !important;
  border-top-left-radius: 1px !important;
  border-top-right-radius: 1px !important; */
  flex: 1 1 0%;
  margin-right: 1px;
  background: #3e5ba6;
  width: 10px;
  display: inline-block;
}
